
import { defineComponent } from 'vue'
import { getInsuranceRecord, InsuranceRecordDto } from '@/api/insurance'
import global from '@/utils/global'

export default defineComponent({
    name: 'InsuranceRecordC',
    props: ['id'],
    data () {
        return {
            pageSize: 3,
            pageNum: 1,
            total: 0,
            slotdate: [] as string[],
            tableData: [] as InsuranceRecordDto[]
            // detail: {} as InsuranceDetailDto
        }
    },
    async created () {
        this.handleCurrentChange()
        // this.detail = result.detail
    },
    methods: {
        async handleCurrentChange () {
            const result = await getInsuranceRecord({
                pageSize: this.pageSize,
                page: this.pageNum,
                policyId: this.id,
                startTime: this.slotdate[0] || '',
                endTime: this.slotdate[1] || ''
            })
            this.total = result.total
            this.tableData = result.data
        },
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        // 导出加减记录
        onExport (id: string) {
            window.location.href =
                process.env.VUE_APP_BASE_URL +
                '/order/export/?orderId=' +
                id +
                '&token=' +
                global.token
            this.$message.success('导出成功')
        }
    }
})
