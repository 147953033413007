
import { defineComponent } from 'vue'
import { WorkerDto } from '@/api/dto'
import { getPolicyPersonList } from '@/api/report'
export default defineComponent({
    name: 'InsurancePeopleC',
    props: ['id'],
    components: {},
    data () {
        return {
            searchValue: '',
            searchText: '',
            pageNum: 1,
            pageSize: 10,
            onlyActive: '0',
            total: 0,
            tableData: [] as WorkerDto[]
        }
    },
    created () {
        this.handleCurrentChange()
    },
    methods: {
        async handleCurrentChange () {
            const result = await getPolicyPersonList({
                policyId: this.id,
                page: this.pageNum,
                pageSize: this.pageSize,
                search: this.searchValue
            })
            this.tableData = result.data
            this.total = result.total
        },
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        search () {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.handleCurrentChange()
        }
    }
})
