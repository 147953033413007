
import alertBox from '@/components/alert-box.vue'
import { defineComponent } from 'vue'
import pageHead from '../components/page-head.vue'
import detail from './components/detail.vue'
import people from './components/people.vue'
import statistics from './components/statistics.vue'
import record from './components/record.vue'
import {
    getInsuranceDetail,
    InsuranceDetailDto,
    renewPreview,
    RenewDataDto,
    renewPolicy
} from '@/api/insurance'
import { exportMonthBill } from '@/api/export'
import global from '@/utils/global'
import router from '@/router'

export default defineComponent({
    name: 'InsuranceNew',
    components: {
        'page-head': pageHead,
        'component-detail': detail,
        'component-people': people,
        'component-statistics': statistics,
        'component-record': record,
        'alert-box': alertBox
    },
    data () {
        return {
            id: '',
            timeMonth: '',
            isExport: false,
            isRenew: false,
            exportType: '',
            detail: {} as InsuranceDetailDto,
            renewData: {} as RenewDataDto,
            renewPage: 1,
            renewPageSize: 5,
            isRenewPeople: false
        }
    },
    async created () {
        this.id = this.$route.params.id as string
        const result = await getInsuranceDetail({
            policyId: this.$route.params.id as unknown as number
        })
        this.detail = result
    },
    methods: {
    // 关闭导出账单弹窗
        close () {
            this.isExport = false
        },
        // 导出账单
        async onExport () {
            if (!this.exportType) {
                this.$message.error('请选择格式')
                return
            }
            if (!this.timeMonth) {
                this.$message.error('请选择日期')
                return
            }
            if (this.exportType === 'date') {
                window.location.href =
          process.env.VUE_APP_BASE_URL +
          '/policy/exportBill/?month=' +
          this.timeMonth +
          '&policyId=' +
          this.id +
          '&token=' +
          global.token
            } else {
                window.location.href =
          process.env.VUE_APP_BASE_URL +
          '/policy/exportPersonBill/?month=' +
          this.timeMonth +
          '&policyId=' +
          this.id +
          '&token=' +
          global.token
            }

            this.$message.success('导出成功')
            await this.close()
        },
        // 导出在保人员名单
        onExportActive () {
            window.location.href =
        process.env.VUE_APP_BASE_URL +
        '/policy/exportActive?policyId=' +
        this.id +
        '&token=' +
        global.token
            this.$message.success('导出成功')
        },
        // 点击一键续保
        async onRenew () {
            this.renewData = await renewPreview({
                policyId: this.detail.policy_id
            })
            this.isRenew = true
        },
        // 确定一键续保
        async onSubmitRenew () {
            await this.$util.confirm('操作提示', '是否确认续保？')
            await renewPolicy({
                policyId: this.detail.policy_id
            })
            this.isRenew = false
            this.isRenewPeople = false
            this.$message.success('续保成功！')
        },
        goAddSubtract (type: string) {
            if (this.detail.is_close === 1) {
                this.$message.warning('该保单已关闭，无法加减保')
                return
            }
            router.push({
                path: '/insurance/addsubtract/' + type + '/' + this.id
            })
        }
    }
})
