
import { defineComponent } from 'vue'
import {
    getCompanyPieStatistics,
    getOccupationPieStatistics
} from '@/api/insurance'
import * as echarts from 'echarts'
export default defineComponent({
    name: 'InsuranceStatistics',
    props: ['id'],
    data () {
        return {
            tab: 'type',
            isEmpty: false,
            onlyActive: false
            // detail: {} as InsuranceDetailDto
        }
    },
    async mounted () {
        this.handleClick()
    },
    methods: {
        // 是否在保
        onIsActive (isActive: boolean) {
            this.onlyActive = isActive
            this.handleClick()
        },
        // 渲染数据
        async handleClick () {
            echarts.dispose(document.getElementById('chart') as HTMLElement)
            let result
            if (this.tab === 'patch') {
                result = await getCompanyPieStatistics({
                    policyId: this.id as string,
                    onlyActive: this.onlyActive ? '1' : ''
                })
            } else {
                result = await getOccupationPieStatistics({
                    policyId: this.id as string,
                    onlyActive: this.onlyActive ? '1' : ''
                })
            }
            if (result.length === 0) {
                this.isEmpty = true
            } else {
                this.isEmpty = false
            }
            const charts = echarts.init(
                document.getElementById('chart') as HTMLElement
            )
            console.log(charts)
            charts.setOption({
                tooltip: {
                    trigger: 'item'
                },
                grid: {
                    top: 0
                },
                legend: {
                    bottom: '0',
                    left: 'left',
                    formatter: (str: string) => {
                        if (str.length > 5) {
                            return str.slice(0, 5) + '...'
                        } else {
                            return str
                        }
                    }
                },
                series: [
                    {
                        name: this.tab === 'patch' ? '被派遣单位' : '工种',
                        type: 'pie',
                        radius: ['35%', '60%'],
                        center: ['50%', '45%'],
                        avoidLabelOverlap: false,
                        label: {
                            normal: {
                                position: 'inside',
                                formatter: '{c}',
                                color: '#fff',
                                fontSize: 14,
                                fontWeight: 700
                            }
                        },
                        toolbox: {
                            show: true
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                fontWeight: 'bold'
                            },
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        data: result.map(item => {
                            return {
                                name: item.name,
                                value: item.count
                            }
                        })
                    }
                ]
            })
        }
    }
})
