
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'InsuranceDetailC',
    props: ['data'],
    created () {
    // console.log(this)
    },
    methods: {}
})
